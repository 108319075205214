$breakpoint-mobile-portrait: 38rem !default;
$breakpoint-mobile-landscape: 40rem !default;

@mixin breakpoint-mobile-landscape {
  @media screen and (max-height: $breakpoint-mobile-landscape) and (orientation: landscape) {
    @content;
  }
}

@mixin breakpoint-mobile-portrait {
  @media screen and (max-height: $breakpoint-mobile-portrait) and (orientation: portrait) {
    @content;
  }
}

@mixin disable-transform {
  *,
  ::before,
  ::after {
    /* stylelint-disable declaration-no-important */
    transform: none !important;
    animation: none !important;
    transition-property: none !important;
  }
}
