@use '@skyscanner/backpack-web/unstable__bpk-mixins/breakpoints';
@use '@skyscanner/backpack-web/unstable__bpk-mixins/tokens';
@use '@skyscanner/backpack-web/unstable__bpk-mixins/utils';

.AppStoreButton {
  &__container {
    display: flex;
    width: 100%;
    padding-top: tokens.bpk-spacing-lg();
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-end;

    @include utils.bpk-rtl {
      justify-content: flex-end;
    }

    @include breakpoints.bpk-breakpoint-tablet {
      justify-content: center;
    }
  }

  &__image {
    width: auto;
    height: 2.4rem;
    margin: tokens.$bpk-one-pixel-rem * 6;

    img {
      position: relative;
      width: auto;
      height: 2.4rem;
    }

    div[class*='bpk-image--'] {
      background-color: transparent;
    }
  }
}
