@use '@skyscanner/backpack-web/unstable__bpk-mixins/breakpoints';
@use '@skyscanner/backpack-web/unstable__bpk-mixins/tokens';

.MainText {
  &__container {
    display: flex;
    width: 100%;
    padding-bottom: tokens.bpk-spacing-lg();
    flex-direction: column;
    justify-content: center;

    @include breakpoints.bpk-breakpoint-mobile {
      height: 100%;
    }
  }

  &__text {
    &--title {
      color: tokens.$bpk-surface-default-day;
      font-weight: 600;

      @include breakpoints.bpk-breakpoint-mobile {
        font-size: tokens.$bpk-font-size-xl;
        line-height: tokens.$bpk-line-height-xl;
      }

      @include breakpoints.bpk-breakpoint-above-mobile {
        font-size: tokens.$bpk-font-size-xxl;
        line-height: tokens.$bpk-line-height-xxl;
      }
    }

    &--subtitle {
      color: tokens.$bpk-surface-default-day;
      font-weight: 200;

      @include breakpoints.bpk-breakpoint-mobile {
        font-size: tokens.$bpk-font-size-xl;
        line-height: tokens.$bpk-line-height-xl;
      }

      @include breakpoints.bpk-breakpoint-above-mobile {
        font-size: tokens.$bpk-font-size-xxl;
        line-height: tokens.$bpk-line-height-xxl;
      }
    }

    &--description {
      color: tokens.$bpk-surface-default-day;
    }
  }
}
