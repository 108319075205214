@use '@skyscanner/backpack-web/unstable__bpk-mixins/tokens';
@use '@skyscanner/backpack-web/unstable__bpk-mixins/utils';

.ScanQR {
  display: flex;
  padding: tokens.$bpk-one-pixel-rem * 12;
  flex-direction: row;

  &__leftColumn {
    display: flex;
    padding: tokens.$bpk-one-pixel-rem * 12;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-start;
    flex-grow: 2;

    @include utils.bpk-rtl {
      align-items: flex-end;
    }
  }

  &__rightColumn {
    display: flex;
    padding: tokens.$bpk-one-pixel-rem * 12;
    flex-direction: column;
    flex-grow: 1;
  }

  img {
    position: relative;
    width: tokens.$bpk-one-pixel-rem * 126;
    height: tokens.$bpk-one-pixel-rem * 126;
  }
}
