/* stylelint-disable selector-class-pattern */
@use '@skyscanner/backpack-web/unstable__bpk-mixins/breakpoints';
@use '@skyscanner/backpack-web/unstable__bpk-mixins/tokens';

%screenshots-container {
  position: relative;
  height: auto;
}

%screenshot-img {
  position: absolute;
  width: 12.5rem;
  height: auto;

  @include breakpoints.bpk-breakpoint-mobile {
    width: 9.785rem;
  }
}

.Screenshot {
  &__container {
    &--iphone {
      @extend %screenshots-container;

      width: 15.3rem;

      @include breakpoints.bpk-breakpoint-mobile {
        width: 12rem;
      }
    }

    &--android {
      @extend %screenshots-container;

      width: 15.9rem;

      @include breakpoints.bpk-breakpoint-mobile {
        width: 11.815rem;
      }
    }
  }

  &__frame {
    &--iphone {
      position: relative;
      z-index: 13;

      div[class*='bpk-image--'] {
        background-color: transparent;
      }
    }

    &--android {
      position: relative;
      z-index: 11;

      div[class*='bpk-image--'] {
        background-color: transparent;
      }
    }
  }

  &__image {
    &--iphone {
      @extend %screenshot-img;

      top: tokens.$bpk-one-pixel-rem * 28;
      left: tokens.$bpk-one-pixel-rem * 22;
      z-index: 12;

      @include breakpoints.bpk-breakpoint-mobile {
        top: tokens.$bpk-one-pixel-rem * 22;
        left: tokens.$bpk-one-pixel-rem * 17.5;
      }
    }

    &--android {
      @extend %screenshot-img;

      top: 2.9rem;
      left: 1.6rem;
      z-index: 10;

      @include breakpoints.bpk-breakpoint-mobile {
        top: 1.015rem;
        left: 1.015rem;
      }
    }
  }
}
