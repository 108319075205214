@use '@skyscanner/backpack-web/unstable__bpk-mixins/tokens';
@use '@skyscanner/backpack-web/unstable__bpk-mixins/utils';
@use 'MobileLanding.common.module';

.MobileLanding {
  width: 100%;
  height: 100%;

  @include MobileLanding.breakpoint-mobile-landscape {
    @include MobileLanding.disable-transform;
  }

  @include MobileLanding.breakpoint-mobile-portrait {
    @include MobileLanding.disable-transform;
  }

  %sticky-panel {
    position: sticky;
    top: 0;

    @include MobileLanding.breakpoint-mobile-portrait {
      position: relative;
    }

    @include MobileLanding.breakpoint-mobile-landscape {
      position: relative;
    }
  }

  .SecondaryPanel {
    &__blue {
      @extend %sticky-panel;

      @include utils.bpk-themeable-property(
        background-color,
        --bpk-mobile-landing-panel-one-background-color,
        tokens.$bpk-core-accent-day
      );
    }

    &__coral {
      @extend %sticky-panel;

      @include utils.bpk-themeable-property(
        background-color,
        --bpk-mobile-landing-panel-two-background-color,
        tokens.$bpk-status-danger-spot-day
      );
    }

    &__green {
      @extend %sticky-panel;

      @include utils.bpk-themeable-property(
        background-color,
        --bpk-mobile-landing-panel-three-background-color,
        tokens.$bpk-status-success-spot-day
      );
    }

    &__yellow {
      @extend %sticky-panel;

      @include utils.bpk-themeable-property(
        background-color,
        --bpk-mobile-landing-panel-four-background-color,
        tokens.$bpk-status-warning-spot-day
      );
    }

    &__purple {
      @extend %sticky-panel;

      @include utils.bpk-themeable-property(
        background-color,
        --bpk-mobile-landing-panel-five-background-color,
        tokens.$bpk-text-secondary-day
      );
    }

    &__red {
      @extend %sticky-panel;

      @include utils.bpk-themeable-property(
        background-color,
        --bpk-mobile-landing-panel-six-background-color,
        tokens.$bpk-status-danger-spot-day
      );
    }
  }
}
