@use '@skyscanner/backpack-web/unstable__bpk-mixins/breakpoints';
@use '@skyscanner/backpack-web/unstable__bpk-mixins/tokens';
@use '../MobileLanding.common.module';

$panel-height: 100vh;

.PanelContainer {
  &__container {
    height: 100%;
    overflow: hidden;
  }

  &__background {
    position: fixed;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100vh;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    overflow: auto;
  }

  &__grid {
    width: 100%;
    height: $panel-height;

    @include MobileLanding.breakpoint-mobile-landscape {
      height: 100%;
    }

    @include MobileLanding.breakpoint-mobile-portrait {
      height: 100%;
    }
  }

  &__gridPrimary {
    width: 100%;
    max-width: tokens.$bpk-one-pixel-rem * 30 * 38;
    margin: 0 auto;
    padding: 0 tokens.bpk-spacing-lg();

    @include breakpoints.bpk-breakpoint-above-tablet {
      height: calc(#{$panel-height} - 7.125rem);
      min-height: 39rem;
    }

    @include breakpoints.bpk-breakpoint-tablet {
      height: calc(#{$panel-height} - 3.375rem);
      min-height: 67rem;
    }

    @include breakpoints.bpk-breakpoint-mobile {
      height: calc(#{$panel-height} - 3.375rem);
      min-height: 40rem;
    }
  }

  &__gridRow {
    &--normal {
      display: flex;
      height: 100%;
      padding: tokens.$bpk-one-pixel-rem * 12 0;

      @include breakpoints.bpk-breakpoint-above-tablet {
        flex-direction: row;
      }

      @include breakpoints.bpk-breakpoint-tablet {
        flex-direction: column;
        justify-content: center;
      }

      @include MobileLanding.breakpoint-mobile-landscape {
        align-items: center;
      }
    }
  }
}
