@use '@skyscanner/backpack-web/unstable__bpk-mixins/breakpoints';

.ScreenshotsWidget {
  &__container {
    display: flex;
    align-items: center;

    @include breakpoints.bpk-breakpoint-mobile {
      height: 100%;
    }
  }

  &__image {
    &--iphone {
      @include breakpoints.bpk-breakpoint-above-mobile {
        top: 0.9375rem;
        left: 2.8125rem;
      }
    }

    &--android {
      @include breakpoints.bpk-breakpoint-mobile {
        display: none;
      }

      @include breakpoints.bpk-breakpoint-above-mobile {
        top: -0.9375rem;
        left: -2.8125rem;
      }
    }
  }
}
