@use '@skyscanner/backpack-web/unstable__bpk-mixins/breakpoints';
@use '@skyscanner/backpack-web/unstable__bpk-mixins/tokens';
@use '@skyscanner/backpack-web/unstable__bpk-mixins/utils';

%grid-column {
  display: flex;
  padding: tokens.$bpk-one-pixel-rem * 12;

  @include breakpoints.bpk-breakpoint-above-tablet {
    height: 100%;
  }
}

.TwoColumnPanel {
  &__column {
    @extend %grid-column;

    flex-direction: column;
    justify-content: space-around;

    @include breakpoints.bpk-breakpoint-tablet {
      align-items: center;
      text-align: center;
    }

    @include breakpoints.bpk-breakpoint-above-tablet {
      width: 50%;
      align-items: center;
      flex-grow: 1;
      text-align: left;

      @include utils.bpk-rtl {
        text-align: right;
      }
    }
  }
}

.PrimaryContentContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  @include breakpoints.bpk-breakpoint-tablet {
    align-items: center;
  }
}

.ScanForApp {
  @include breakpoints.bpk-breakpoint-mobile {
    display: none;
  }
}
